import { render, staticRenderFns } from "./ThumbUp.vue?vue&type=template&id=3cf4d231&scoped=true&"
import script from "./ThumbUp.vue?vue&type=script&lang=js&"
export * from "./ThumbUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf4d231",
  null
  
)

export default component.exports